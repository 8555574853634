import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide, type SwiperProps } from "swiper/react";

import "./slider.css";

import { CaseStudyCard, CaseStudyComponent } from "~/components/UI/CaseStudyCard";

import { NavButton } from "~/components/UI/NavButton";
import {
  braintrustCaseStudy,
  googleCaseStudy,
  marketingEvolutionCaseStudy,
  takiCaseStudy,
  zapierCaseStudy,
} from "~/data/caseStudies";

SwiperCore.use([Navigation]);

export const OurWorkSlider = () => {
  const cases = [
    braintrustCaseStudy,
    googleCaseStudy,
    zapierCaseStudy,
    marketingEvolutionCaseStudy,
    takiCaseStudy,
  ];

  const slides = cases.map((client, index) => {
    return (
      <SwiperSlide key={index} style={{ cursor: "pointer", width: "auto" }}>
        <div
          className="our-work-item relative z-[1] w-[45.5rem] pl-6 max-md:w-[21.6rem]"
          data-gsap-animation="fadeInUp"
          data-gsap-delay={`0.${index + 1}`}
          data-gsap-offset={(index + 1) * 30}
        >
          <CaseStudyCard {...client} component={CaseStudyComponent.HomeSliderCard} />
        </div>
      </SwiperSlide>
    );
  });

  const config: SwiperProps = {
    loop: true,
    slidesPerView: 1,
    loopAdditionalSlides: 1,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between">
        <h3 className="heading-3" data-gsap-animation="fadeInUp">
          Our work
        </h3>
        <NavigationComponent />
      </div>
      <div className="pt-24 max-md:pt-10">
        <div className="our-work-slider-container mx-[-1.5rem] w-[45.5rem] max-md:w-full">
          <Swiper {...config}>{slides}</Swiper>
        </div>
      </div>
    </div>
  );
};

const NavigationComponent = ({ disableNext }: { disableNext?: boolean }) => (
  <div className="flex items-center gap-10 max-md:hidden" data-gsap-animation="fadeInUp">
    <NavButton direction="prev" />
    <NavButton direction="next" disabled={disableNext} />
  </div>
);
